document.addEventListener("turbo:load", function() {
  $(function () {
    $('[data-toggle="offcanvas"]').on('click', function () {
      const offcanvas = $('.offcanvas-collapse');
      offcanvas.toggleClass('open');

      if (offcanvas.hasClass('open')) {
        offcanvas.removeClass('d-none');
      } else {
        offcanvas.addClass('d-none');
      }
    });
  });

  var navs = document.getElementsByClassName("search-nav-item");
  for (var i = 0; i < navs.length; i++) {
    navs[i].addEventListener("click", function() {
    var current = document.getElementsByClassName("active-nav-item-search");
    current[0].className = current[0].className.replace(" active-nav-item-search", "");
    this.className += " active-nav-item-search";
    });
  }

  const navItems = document.querySelectorAll('.nav-item.dropdown');

  // Hide other dropdown menus when hovering over a nav item
  navItems.forEach(function (navItem) {
    navItem.addEventListener('mouseenter', function () {
      const currentlyShownDropdown = document.querySelector('.nav-item.dropdown.show');
      if (currentlyShownDropdown && currentlyShownDropdown !== navItem) {
        currentlyShownDropdown.classList.remove('show');
        const currentlyShownLink = currentlyShownDropdown.querySelector('.nav-link');
        if (currentlyShownLink) {
          currentlyShownLink.setAttribute('aria-expanded', 'false');
        }
        const dropdownMenu = currentlyShownDropdown.querySelector('.dropdown-menu');
        if (dropdownMenu) {
          dropdownMenu.classList.remove('show');
        }
      }
    });
  });
});
