import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  connect() {
    const defaultConfig = {
      searchField: [],
      controlInput: null,
      openOnFocus: false,
    };

    if (this.element.id === 'committee-pick-date') {
      defaultConfig.render = {
        option: function (data, escape) {
          return `<div>
                    <span>${escape(data.text)}</span>
                    <span class="cmhi-items-count ml-2">${escape(data.count)}</span>
                  </div>`;
        },
        item: function (data, escape) {
          return `<div>
                    <span>${escape(data.text)}</span>
                    <span class="cmhi-items-count ml-2">${escape(data.count)}</span>
                  </div>`;
        }
      };
    }

    // Initialize Tom Select when the controller is connected
    const tomSelectInstance = new TomSelect(this.element, defaultConfig);

    document.getElementById(tomSelectInstance.control.id).addEventListener('click', function() {
      tomSelectInstance.open();
    });

    document.getElementById(tomSelectInstance.control.id).addEventListener('keydown', function(event) {
      if (event.key === 'Enter') {
        tomSelectInstance.open();
        event.preventDefault();
      }
    });

    if (
      Object.keys(tomSelectInstance.options).length > 6 &&
      !tomSelectInstance.dropdown_content.closest('.date-dropdown-wrapper')
    ) {
      tomSelectInstance.dropdown_content.classList.add('ts-scrollable-dropdown');
    }
  }
}
