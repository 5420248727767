import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["form", "legResultsList", "searchLegInput", "allMembersList", "filterForm", "houseSenateLeadershipBlock"];

  initialize() {
    this.debounceSubmit = debounce(() => {
      this.requestSubmit();
    }, 300);

    this.debounceSubmitForFilter = debounce(() => {
      this.requestSubmitForFilter();
    }, 300);

    document.addEventListener('click', (event) => {
      this.clickOutsideSearchRemove(event);
    });
  }

  navigate(event) {
    const url = event.target.value;
    if (url) {
      window.location.href = url;
    }
  }

  switchTab = (e) => {
    setTimeout(() => {
      $('.tab-pane.fade').removeClass('active show');
      $('#' + `${e.target.value}`).addClass('active show');
    }, 400);

    if (e.target.value === 'leg-all-members-text') {
      this.handleAllMembersTabContent();
    } else if (e.target.value === 'leg-leadership-text') {
      this.handLeadershipTabContent();
    }
  }

  updateSortFilterList() {
    if (this.hasFilterFormTarget) {
      this.debounceSubmitForFilter();
    }
  }

  handleAllMembersTabContent() {
    if (this.hasAllMembersListTarget) {
      $(this.allMembersListTarget).show();
    }

    if (this.hasHouseSenateLeadershipBlockTarget) {
      $(this.houseSenateLeadershipBlockTarget).hide();
    }
  }

  handLeadershipTabContent() {
    if (this.hasAllMembersListTarget) {
      $(this.allMembersListTarget).hide();
    }

    if (this.hasHouseSenateLeadershipBlockTarget) {
      $(this.houseSenateLeadershipBlockTarget).show();
    }
  }

  submit() {
    this.debounceSubmit();
  }

  handleEnter(event) {
    event.preventDefault();

    if (!this.hasLegResultsListTarget) return;

    const selectedElement = this.legResultsListTarget.querySelector("a.selected");
    if (selectedElement) {
      window.location.href = selectedElement.href;
    }
  }

  handleHover(event) {
    this.clearSelectedClass();
    event.currentTarget.classList.add("selected");
  }

  clearHover() {
    if (!this.hasLegResultsListTarget) return;

    this.clearSelectedClass();
    const firstItem = this.legResultsListTarget.querySelector("a");
    if (firstItem) {
      firstItem.classList.add("selected");
    }
  }

  clearSelectedClass() {
    if (!this.hasLegResultsListTarget) return;

    const selectedItems = this.legResultsListTarget.querySelectorAll("a.selected");
    selectedItems.forEach((item) => item.classList.remove("selected"));
  }

  requestSubmit() {
    this.formTarget.requestSubmit();
  }

  requestSubmitForFilter() {
    this.filterFormTarget.requestSubmit();
  }

  clearInput() {
    if (this.hasLegResultsListTarget && this.hasSearchLegInputTarget) {
      this.searchLegInputTarget.value = "";
      this.debounceSubmit();
    }
  }

  clickOutsideSearchRemove(event) {
    const isClickInsideSearch = event.target.closest("#search-legislators") || event.target.closest("#search-results-list");

    if (!isClickInsideSearch) {
      this.clearInput();
    }
  }
}
